const integrations = [
  {
    src: '/svg/integration-logo1.svg',
    company: 'Barracuda'
  },
  {
    src: '/svg/aws-cloudtrail.svg',
    company: 'AWS Cloudtrail'
  },
  {
    src: '/svg/m365.svg',
    company: 'Microsoft 365'
  },
  {
    src: '/svg/slack.svg',
    company: 'Slack'
  },
  {
    src: '/svg/integration-logo2.svg',
    company: 'Checkpoint'
  },
  {
    src: '/svg/integration-logo3.svg',
    company: 'Paloalto'
  },
  {
    src: '/svg/integration-logo4.svg',
    company: 'SonicWall'
  },
  {
    src: '/svg/integration-logo5.svg',
    company: 'Fortinet'
  },
  {
    src: '/svg/integration-logo6.svg',
    company: 'Sophos'
  },
  {
    src: '/svg/integration-logo7.svg',
    company: 'Azure'
  },
  {
    src: '/svg/integration-logo8.svg',
    company: 'Suite'
  },
  {
    src: '/svg/integration-logo9.svg',
    company: 'Apache'
  },
  {
    src: '/svg/integration-logo10.svg',
    company: 'Kaspersky'
  },
  {
    src: '/svg/tomcat.svg',
    company: 'Tomcat'
  },
  {
    src: '/svg/aws-route53.svg',
    company: 'AWS Route 53'
  },
  {
    src: '/svg/aws-ec2.svg',
    company: 'AWS EC2'
  },
  {
    src: '/svg/aws-billing.svg',
    company: 'AWS Billing'
  },
  {
    src: '/svg/aws-cloudwatch.svg',
    company: 'AWS Cloudwatch'
  },
  {
    src: '/svg/amazon-s3.svg',
    company: 'AWS S3'
  },
  {
    src: '/svg/duo.svg',
    company: 'Duo'
  }
];

export default integrations;

