const spanVariants = {
  hidden: {
    x: -120,
    opacity: 0
  },
  visible: {
    x: 0,
    opacity: 1
  }
};

export default spanVariants;

