import {motion, AnimatePresence} from 'framer-motion';
import ArrowLink from '../../buttons/ArrowLink/ArrowLink';

export const SliderImage = ({data, theme}) => {
  const {image, imageLight} = data;
  const imgSrc = theme === 'dark' ? image : imageLight;
  const imgStyle = {backgroundImage: `url(${imgSrc})`};

  return (
    <div className='image-slide'>
      <AnimatePresence>
        <motion.div
          className='img'
          style={imgStyle}
          initial={{opacity: 0, scale: 0.95, x: 200}}
          animate={{opacity: 1, scale: 1, x: 0}}
          exit={{opacity: 0, scale: 0.95, x: -200}}
          transition={{duration: 0.5}}
          ease='easeInOut'
        ></motion.div>
      </AnimatePresence>
    </div>
  );
};

export const SliderContent = ({data}) => {
  const {subtitle, subtitleClass, title, text, linkText, path} = data;

  return (
    <div className='content-slider'>
      <AnimatePresence>
        <motion.section
          className='content-slide'
          initial={{opacity: 0, scale: 0.95, y: 200}}
          animate={{opacity: 1, scale: 1, y: 0}}
          exit={{opacity: 0, scale: 0.95, y: -200}}
          transition={{duration: 0.5}}
          ease='easeInOut'
        >
          <h3 className={`subtitle ${subtitleClass}`}>{subtitle}</h3>
          <h2 className='slide-title'>{title}</h2>
          <p className='slide-text'>{text}</p>
          <ArrowLink url={path} text={linkText} />
        </motion.section>
      </AnimatePresence>
    </div>
  );
};

