'use client';

import {useEffect, useRef} from 'react';

const useTopStyle = (initialHeight = 0) => {
  const section = useRef(null);

  useEffect(() => {
    if (!section.current) return;
    function handleResize() {
      let sectionTopStyle;
      const sectionHeight = section.current.clientHeight;
      const windowHeight = window.innerHeight;

      if (windowHeight >= sectionHeight) {
        sectionTopStyle = initialHeight; // header height
      }
      else if (windowHeight < sectionHeight) {
        sectionTopStyle = windowHeight - sectionHeight - initialHeight;
      }
      section.current.style.top = `${sectionTopStyle}px`;
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return section;
};

export default useTopStyle;
