import Image from 'next/image';
import Link from 'next/link';

const BookBtn = ({url = 'contact', text = 'Book a demo'}) => {
  return (
    <Link href={`/${url}`} legacyBehavior>
      <a className='btn book-btn'>
        {text}
        <span className='arrow-wrapper'>
          <span className='arrow'>
            <Image src='/svg/arrow-top-right.svg' className='svg' fill alt='book a demo' unoptimized/>
          </span>
        </span>
      </a>
    </Link>
  );
};

export default BookBtn;

