import Image from 'next/image';
import Link from 'next/link';

const ArrowLink = ({url = 'company', text = 'Learn more'}) => {
  return (
    <Link href={`${url}`} className='arrow-link' legacyBehavior>
      <a>
        {text}
        <span className='arrow-wrapper'>
          <span className='arrow'>
            <Image src='/svg/arrow-top-right.svg' className='svg' fill alt='link' unoptimized/>
          </span>
        </span>
      </a>
    </Link>
  );
};

export default ArrowLink;

