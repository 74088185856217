const slider = [
  {
    subtitle: 'Security',
    subtitleClass: 'blue',
    title: 'Full stack security',
    text: 'Our XDR agent and managed cloud SIEM will supercharge your Security Operations Center and automate your compliance needs.',
    linkText: 'More about security',
    path: '/security',
    image: '/img/security-img1.png',
    imageLight: '/img/security-img1-light.png'
  },
  {
    subtitle: 'Observabilty',
    subtitleClass: 'purple',
    title: 'Insight at scale',
    text: 'Our cloud analytics platform expands visibility across your endpoints, applications, network, and cloud services. All of them.',
    linkText: 'More about observability',
    path: '/observability',
    image: '/img/security-img2.png',
    imageLight: '/img/security-img2-light.png'
  },
  {
    subtitle: 'Operations',
    subtitleClass: 'orange',
    title: 'Operations, simplified',
    text: 'Improve efficiency and orchestrate operations with our integrated platform to query, manage, and monitor on premise and cloud assets.',
    linkText: 'More about operations',
    path: '/operations',
    image: '/img/security-img3.png',
    imageLight: '/img/security-img3-light.png'
  }
];

export default slider;

