'use client';

import {useContext} from 'react';
import slider from '../../data/slider';
import {ThemeContext} from '../../utils/ThemeContext';
import SliderMobileItem from './SliderMobileItem/SliderMobileItem';

const SliderMobile = () => {
  const {theme} = useContext(ThemeContext);

  const toggleItem = event => {
    const securityItem = event.target.parentElement.parentElement;
    const items = Array.from(securityItem.parentElement.childNodes);
    items.forEach(item => {
      if (item !== securityItem) {
        item.classList.remove('open');
      } else {
        item.classList.toggle('open');
      }
    });
  };

  const sliderItems = slider.map((item, index) => {
    return <SliderMobileItem theme={theme} data={item} toggleItem={toggleItem} key={index} className={index === 0 ? 'security-item open' : 'security-item'} />;
  });

  return (
    <div className='security honeycomb'>
      <div className='security-items'>{sliderItems}</div>
    </div>
  );
};

export default SliderMobile;

