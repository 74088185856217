'use client';

import {useEffect, useRef, useState, useContext} from 'react';
import {Controller, Scene} from 'react-scrollmagic-r18';
import {ThemeContext} from 'utils/ThemeContext';
import slider from 'data/slider';
import {SliderImage, SliderContent} from './SliderItem/SliderItem';

const Slider = () => {
  const [blockOpacity0, setBlockOpacity0] = useState(null);
  const [blockOpacity1, setBlockOpacity1] = useState(null);
  const [blockOpacity2, setBlockOpacity2] = useState(null);
  const [slideNum, setSlideNum] = useState(1);
  const {theme} = useContext(ThemeContext);
  const sliderRef = useRef();

  useEffect(() => {
    const scroll_listener = () => {
      if (sliderRef.current === null) return;

      const containerPos = sliderRef.current.getBoundingClientRect().top;
      const containerHeight = sliderRef.current.getBoundingClientRect().height;

      const pixels_past = (window.scrollY - containerPos) - window.innerHeight;

      if (pixels_past / containerHeight < 0.33) {
        setBlockOpacity0(true);
        setBlockOpacity1(false);
        setBlockOpacity2(false);
        setSlideNum(1);
      } else if (pixels_past / containerHeight < 0.66) {
        setBlockOpacity0(false);
        setBlockOpacity1(true);
        setBlockOpacity2(false);
        setSlideNum(2);
      } else {
        setBlockOpacity0(false);
        setBlockOpacity1(false);
        setBlockOpacity2(true);
        setSlideNum(3);
      }
    };

    window.addEventListener('scroll', scroll_listener);
    return () => window.removeEventListener('scroll', scroll_listener);
  }, [sliderRef, setBlockOpacity0, setBlockOpacity1, setBlockOpacity2, setSlideNum]);

  return (
    <div className='scrollmagic' ref={sliderRef}>
      <Controller>
        <Scene duration={2200} pin={{pushFollowers: false}} triggerHook={0}>
          <div className='scroll-slider honeycomb'>
            <div className='container'>
              <div className='slider-container'>
                {blockOpacity0 && (
                  <div className='slider-item'>
                    <SliderImage data={slider[0]} theme={theme} />
                    <SliderContent data={slider[0]} />
                  </div>
                )}
                {blockOpacity1 && (
                  <div className='slider-item'>
                    <SliderImage data={slider[1]} theme={theme} />
                    <SliderContent data={slider[1]} />
                  </div>
                )}
                {blockOpacity2 && (
                  <div className='slider-item'>
                    <SliderImage data={slider[2]} theme={theme} />
                    <SliderContent data={slider[2]} />
                  </div>
                )}
                <div className='pagination'>
                  <p className='slide-num'>0{slideNum}</p>
                  <div className='track'>
                    <div className='scale' style={{height: `${slideNum * 33.3}%`}}></div>
                  </div>
                  <p className='slide-num'>03</p>
                </div>
              </div>
            </div>
          </div>
        </Scene>
      </Controller>
    </div>
  );
};

export default Slider;

