import ArrowLink from 'components/buttons/ArrowLink/ArrowLink';

const SliderMobileItem = ({data, toggleItem, className, theme}) => {
  const {subtitle, title, text, linkText, path, image, imageLight} = data;
  const imgSrc = theme === 'dark' ? image : imageLight;
  const imgStyle = {backgroundImage: `url(${imgSrc})`};

  return (
    <section className={className}>
      <div className='card-header'>
        <h3 className='subtitle'>{subtitle}</h3>
        <button className='toggle-btn' onClick={toggleItem}></button>
      </div>

      <div className='card-body'>
        <div className='image-slide'>
          <div className='img' style={imgStyle}></div>
        </div>
        <div className='content-slider'>
          <div className='content-slide'>
            <h3 className='subtitle'>{subtitle}</h3>
            <h2 className='title'>{title}</h2>
            <p className='text'>{text}</p>
            <ArrowLink url={path} text={linkText} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderMobileItem;

