const heroVariants = {
  hidden: {
    y: 232,
    opacity: 0
  },
  visible: {
    y: 0,
    opacity: 1
  }
};

export default heroVariants;

